<template>
  <q-form ref="editForm">
    <div v-if="isOld">
      <c-tab
        class="q-mt-sm"
        type="tabcard"
        align="left"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        :dense="true"
        :height="tabHeight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <!-- :data="month.depts.filter(i => i.deptCd === tab.deptCd).results" -->
          <c-table
            ref="table"
            title="환경안전보건 종합점검 시트 목록"
            :merge="grid.merge"
            :columns="grid.columns"
            :data="tab.results"
            :gridHeight="gridHeight"
            :editable="false"
            :usePaging="false"
            :isExcelDown="true"
            :filtering="false"
            :isFullScreen="true"
            :columnSetting="false"
          >
            <template slot="suffixTitle">
              <font class="text-negative" style="font-size:0.9em;font-weight:500;">
                ※ 목록은 작성부서와 작성월의 전월 데이터 기준입니다.
              </font>
            </template>
          </c-table>
        </template>
      </c-tab>
    </div>
    <div class="text-h6" v-else>
      <q-chip square>
        <q-avatar color="red" text-color="white">!</q-avatar>
        작성부서의 전월 실적이 존재하지 않습니다. 안전보건목표 업무에서 등록하세요.
      </q-chip>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'env-saf-kpi-performance',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopEnvSafKpiId: '',
      }),
    },
    target: {
      type: Object,
      default: () => ({
        sopEnvSafKpiId: '',
        plantCd: '',
        targetMonth: '',
        prevMonth: '',
        envSafStatusCd: '',
        targetTitle: '',
        writeDeptCd: '',
        regUserId: '',
        chgUserId: '',
        targetList: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      month: {
        saiSafetyHealthyTargetMonthId: '',  // 안전보건목표 일련번호
        saiSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        yearmonth: '',  // 년월
        saiSafetyHealthyTargetMonthStepCd: '',  // 진행상태
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        depts: [],
      },
      tab: '',
      grid: {
        merge: [{ index: 0, colName: "targetName" }],
        columns: [],
        data: [],
      },
      editable: false,
      detailUrl: '',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let height = (this.contentHeight - 130);
      if (height < 650) {
        height = 650;
      }
      return String(height) + 'px';
    },
    gridHeight() {
      let height = (this.contentHeight - 180);
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    isOld() {
      return Boolean(this.month.saiSafetyHealthyTargetMonthId)
    },
    tabItems() {
      let items = [];
      if (this.month.depts && this.month.depts.length > 0) {
        this.$_.forEach(this.month.depts, dept => {
          items.push({
            name: dept.deptCd, 
            label: dept.deptName,
            class: 'tab-bluegrey', icon: 'dangerous', 
            deptCd: dept.deptCd, 
            results: dept.results, 
            completeFlag: dept.completeFlag,
            badge: dept.completeFlag === 'Y' ? '완료' : null
          })
        })
      }
      return items;
    },
  },
  watch: {
    'target.prevMonth'() {
      this.getDetail();  
    },
    'target.writeDeptCd'() {
      this.getDetail();  
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.safetyHealthy.month.envSafKpiGet.url;
      // code setting
      this.setGridHeader()
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.target.prevMonth && this.target.writeDeptCd)
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        prevMonth: this.target.prevMonth,
        writeDeptCd: this.target.writeDeptCd,
      };
      this.$http.request((_result) => {
        if (_result.data) {
          this.$_.extend(this.month, _result.data);
          this.tab = this.month.depts[0].deptCd
        } else {
          this.$set(this.$data, 'month', this.$options.data().month)
        }
      },);
    },
    setGridHeader() {
      let yearmonth = this.target.prevMonth;
      let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();
      let childCols = [];
      for (let idx = 1; idx <= yearmonthDays; idx++) {
        let isHoliday = this.isHoliday(`${yearmonth}-${this.$_.padStart(idx, 2, '0')}`);
        childCols.push({
          name: `day${idx}`,
          field: `day${idx}`,
          label: `${idx}`,
          align: 'center',
          style: 'width:30px',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          color: 'blue', 
          month: 1,
          disableTarget: 'ehsFlag',
          disableCon: 'N',
          headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
          tdColor: isHoliday ? '#D9D9D9' : null
        })
      }
      let performanceCols = {
        name: 'performance',
        field: 'performance',
        label: '점검일',
        child: childCols
      };
      this.grid.columns = [
        {
          name: 'targetName',
          field: 'targetName',
          label: '구분',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'detailName',
          field: 'detailName',
          label: '점검내용',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          fix: true,
        },
        {
          name: 'inspectionCycle',
          field: 'inspectionCycle',
          label: '점검주기',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          fix: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: '담당자',
          align: 'left',
          style: 'width:150px',
          sortable: false,
          fix: true,
        },
        {
          name: 'result',
          field: 'result',
          label: '점검결과',
          align: 'center',
          child: [
            {
              name: 'resultTeamLeader',
              field: 'resultTeamLeader',
              label: '해당팀장',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultSupportTeam',
              field: 'resultSupportTeam',
              label: '생산지원팀',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
            {
              name: 'resultManager',
              field: 'resultManager',
              label: '관리감독자',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              codeGroupCd: 'SAI_SAFETY_HEALTHY_TARGET_RESULT_CD',
              sortable: false,
              setHeader: true,
            },
          ]
        },
        {
          name: 'relatedEvidence',
          field: 'relatedEvidence',
          label: '관련근거',
          align: 'left',
          style: 'width:270px',
          type: 'html',
          sortable: false
        },
      ];
      this.grid.columns.splice(4, 0, performanceCols)
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else if (this.$_.findIndex(this.popupParam.holidaies, { holiday: day }) > -1) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>